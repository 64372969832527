<template>
  <div>
    <div class="vs-con-loading__container">
      <ag-grid-table
        :animateRows="true"
        :suppressRowClickSelection="false"
        :columnDefs="columnDefs"
        :rowData="templates"
        :gridOptions="gridOptions"
        rowSelection="single"
      >
        <template v-if="true" v-slot:actionBar>
          <ag-action-bar
            :is_add="$can('create','email')"
            :is_edit="$can('create','email')"
            @add="onBtnAdd"
            @edit="onBtnEdit"
          ></ag-action-bar>
        </template>
      </ag-grid-table>
    </div>
  </div>
</template>

<script>

import HTTP from "@/http-common";
import AgGridTable from "@/components/tableAgGrid/Index";
import ActionsBar from "@/components/tableAgGrid/ActionsBar";
import moment from "moment";
import FieldsCustomEmail from "../../fields/email/fieldsEmails";
const NAME = "EmailIndex";
export default {
  name: NAME,
  components: {
    "ag-grid-table": AgGridTable,
    "ag-action-bar": ActionsBar,
  },
  data() {
    return {
      gridOptions: {
        localeText: { noRowsToShow: `${this.$t("show_not_data")}` },
      },
      templates: [],
      isLoading: true,
      columnDefs: FieldsCustomEmail.columnDefs,
    };
  },
  computed: {
    isEmpty() {
      return !this.templates.length;
    },
  },
  watch: {},
  methods: {
    onBtnAdd() {
      this.$router.push({ name: "new-email" });
    },
    onBtnEdit() {
      let data = this.gridOptions.api.getSelectedNodes();
      if (data.length === 1) {
        this.$router.push({
          name: "edit-email",
          params: { id: data[0].data.id },
        });
      } else {
        this.$vs.notify({
          text: this.$t("vehicles.selecction"),
          color: "danger",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      }
    },
    async requestTemplates() {
      this.isLoading = true;
      try {
        let templatesGet = await HTTP.query("v1/custom-emails");
        if (templatesGet.data.status === "success") {
          this.templates = templatesGet.data.templates.map((template) => {
            return {
              id: template.id,
              name: template.name,
              company: template.company,
              status: template.status,
              created_at: moment(template.created_at).format("DD/MM/YYYY"),
              newness_id: template.newness_id
            };
          });
        }
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
  },

  async mounted() {
    await this.requestTemplates();
  },
  created() {},
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}

.container-editor-email {
  display: flex;
  flex-direction: column;
  height: 700px;
}
</style>