<template lang="pug">
    email-index
</template>

<script>
import EmailIndex from "@/components/email/Index.vue";
import { validatePermission } from "@/Ability";

const COMPONENT_NAME = "ViewIndexEmail";

export default {
  name: COMPONENT_NAME,
  components: {
    "email-index": EmailIndex,
  },
  created() {
    validatePermission(this, "read", "email", "Home");
  },
};
</script>